import Head from 'next/head'
import React from 'react'
import type { SeoResult } from '../data/fragments/SeoFragment'
import { getBaseUrl } from '../utils/getBaseUrl'

type SeoWithoutId = Omit<SeoResult, 'id'>

function seoWithFallback(seo: SeoWithoutId | undefined, fallback?: SeoResult) {
	const title = seo?.title ?? seo?.ogTitle ?? fallback?.title ?? fallback?.ogTitle
	const description =
		seo?.description ?? seo?.ogDescription ?? fallback?.description ?? fallback?.ogDescription

	const ogTitle = seo?.ogTitle ?? seo?.title ?? fallback?.ogTitle ?? fallback?.title
	const ogDescription =
		seo?.ogDescription ?? seo?.description ?? fallback?.ogDescription ?? fallback?.description

	const ogImage = seo?.ogImage ?? fallback?.ogImage

	return {
		title,
		description,
		ogTitle,
		ogDescription,
		ogImage,
	}
}

export type SeoHeadProps = {
	titleSuffix?: string
	url?: string
	seo?: SeoWithoutId
	fallback?: SeoResult
	formatTitle?: (title?: string | undefined) => string
	dump?: boolean
	ogType?: string
}

export function SeoHead({
	seo,
	fallback,
	formatTitle,
	dump,
	titleSuffix,
	url,
	ogType,
}: SeoHeadProps) {
	const suffix = titleSuffix ?? ' - HEY FOMO'
	const meta = React.useMemo(() => {
		return seoWithFallback(seo, fallback)
	}, [seo, fallback])

	const title = formatTitle ? formatTitle(meta.title) : meta.title

	const canonicalUrl = React.useMemo(() => {
		if (url && url.indexOf('/') === 0) {
			return getBaseUrl() + url
		}
	}, [url])

	return (
		<>
			{dump && <pre>{JSON.stringify({ ...meta, title }, null, 2)}</pre>}
			<Head>
				{!!title && (
					<title id="title">
						{title}
						{suffix}
					</title>
				)}
				{canonicalUrl && (
					<>
						<meta property="og:url" content={canonicalUrl} />
						<link rel="canonical" href={canonicalUrl} />
					</>
				)}

				{!!meta.description && <meta name="description" content={meta.description} />}

				{!!meta.ogTitle && (
					<>
						<meta property="og:title" content={meta.ogTitle} />
						<meta name="twitter:title" content={meta.ogTitle} />
					</>
				)}

				{!!meta.ogDescription && (
					<>
						<meta property="og:description" content={meta.ogDescription} />
						<meta name="twitter:description" content={meta.ogDescription} />
					</>
				)}

				{!!meta.ogImage?.url && (
					<>
						<meta name="twitter:image" content={meta.ogImage.url} />

						<meta property="og:image" content={meta.ogImage.url} />
						{!!meta.ogImage?.type && <meta property="og:image:type" content={meta.ogImage.type} />}
						{!!meta.ogImage?.alt && <meta property="og:image:alt" content={meta.ogImage.alt} />}
						{!!meta.ogImage?.width && (
							<meta property="og:image:width" content={String(meta.ogImage.width)} />
						)}
						{!!meta.ogImage?.height && (
							<meta property="og:image:height" content={String(meta.ogImage.height)} />
						)}
					</>
				)}

				<meta property="og:site_name" content="HEY FOMO" />
				<meta property="og:locale" content="cs" />
				<meta property="og:type" content={ogType ?? 'website'} />

				<meta name="twitter:card" content={meta.ogImage?.url ? 'summary_large_image' : 'summary'} />
				<meta name="twitter:site" content="@heyfomocz" />
				<meta name="twitter:creator" content="@heyfomocz" />
			</Head>
		</>
	)
}
