import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import React from 'react'
import { useSharedContext } from '../../libs/routing/sharedContext'
import { getUrlFromLinkResult } from '../utils/getUrlFromLinkResult'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import style from './Footer.module.sass'
import { Icon } from './Icon'
import { Link } from './Link'

export const Footer: FunctionComponent = () => {
	const { footer } = useSharedContext()

	return (
		<div className={style.wrapper}>
			<Container>
				<div className={clsx(style.logo, footer.logo && style.view_contemberLogo)}>
					{footer.logo ? (
						<ContemberImage image={footer.logo} objectFit="contain" />
					) : (
						<Icon name="fomoLogoFooter" />
					)}
				</div>
				<div className={style.copyright}>
					<p>
						© 2001 - {new Date().getFullYear()} Copyright{' '}
						<Link href="https://www.cncenter.cz/">CZECH NEWS CENTER a.s.</Link> a dodavatelé obsahu
					</p>
				</div>
				<nav className={style.nav}>
					<ul className={clsx(style.list)}>
						{footer.links.map(
							(link) =>
								link.link && (
									<li className={style.item} key={link.id}>
										<Link
											className={style.link}
											link={link.link}
											dataAttributes={{
												'track-click': JSON.stringify({
													event: 'clickTo_navigation',
													menu: { type: 'footer' },
													link: {
														text: link.label,
														url: getUrlFromLinkResult(link.link).href,
													},
												}),
											}}>
											{link.label}
										</Link>
									</li>
								)
						)}
					</ul>
				</nav>
			</Container>
		</div>
	)
}
