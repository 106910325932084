import React from 'react'
import { IfHydrated } from '../../libs/next/hydratation'
import type { LayoutRenderer } from '../../libs/routing/RoutingPage'
import { EditLink } from '../components/EditLink'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import style from './layout.module.sass'
import type { RoutingPageProps } from '../../libs/routing/RoutingPage'

export const renderLayout: LayoutRenderer = (children, props) => {
	return <LayoutInner props={props}>{children}</LayoutInner>
}

export function LayoutInner(props: { children: React.ReactNode; props: RoutingPageProps }) {
	const headerLogo = 'headerLogo' in props.props.routeData ? props.props.routeData.headerLogo : null
	const headerHideNavigationList =
		'headerHideNavigationList' in props.props.routeData
			? props.props.routeData.headerHideNavigationList
			: false
	const isKiosek = props.props.metaData.info.request.query.kiosek === ''

	React.useEffect(() => {
		if (isKiosek || localStorage.getItem('kiosek') === '1') {
			localStorage.setItem('kiosek', '1')
			document.body.classList.add('kiosek')
		}
	}, [isKiosek])

	return (
		<div className={style.wrapper}>
			<Header logo={headerLogo} hideNavigationList={headerHideNavigationList} />
			{props.children}
			<Footer />
			{
				<IfHydrated
					yes={() => (
						<EditLink
							url={
								props.props.metaData.info.route.params &&
								'editLink' in props.props.metaData.info.route.params
									? props.props.metaData.info.route.params?.editLink
									: '#'
							}
						/>
					)}
				/>
			}
		</div>
	)
}
