import React from 'react'
import { Button } from './Button'

export function EditLink(props: { url?: string }) {
	const show = React.useMemo(() => {
		// Helper: localStorage.setItem('showContemberEditLinks', 'true')
		return (
			typeof window !== 'undefined' &&
			window.localStorage.getItem('showContemberEditLinks') === 'true'
		)
	}, [])
	return show && props.url ? (
		<div style={{ position: 'fixed', bottom: 0, left: 0, padding: '.5em', zIndex: 10 }}>
			<Button type="link" link={props.url} view="light">
				Upravit
			</Button>
		</div>
	) : null
}
