import React, { useEffect } from 'react'
import type { ArticleType } from '../../generated/content'
import { useAuth } from '../auth/UserContext'
import type { ArticleResult } from '../data/fragments/ArticleFragment'
import type { AuthorResult } from '../data/fragments/AuthorFragment'
import type { TagResult } from '../data/fragments/TagFragment'

export interface GtmArticleData {
	id: string
	title?: string
	tags?: TagResult[]
	authors?: AuthorResult[]
	publishAt?: ArticleResult['publishAt']
	type?: ArticleType
	contentParts?: string[]
}

interface GtmPageDataProps {
	visible?: boolean
	page: {
		type: string
	}
	article?: GtmArticleData
	error?: { type: string; message?: string }
}

export function useGtmPageData(props: GtmPageDataProps): void {
	const serialized = JSON.stringify({ ...props, visible: props.visible ?? true })
	const auth = useAuth()

	const authStatus = auth.status
	const isArticle = Boolean(props.article)

	const [dataSent, setDataSent] = React.useState(false)
	const [userDataSent, setUserDataSent] = React.useState(false)

	useEffect(() => {
		const { page, article, error, visible } = JSON.parse(serialized) as GtmPageDataProps
		const urlQuery = window.location.search
		const urlParams = new URLSearchParams(urlQuery)
		const isKiosek = urlParams.has('kiosek')

		if (visible && !dataSent) {
			window.dataLayer?.push({
				app: {
					systemId: 'heyfomo',
					service: {
						entitiy: 'cnc',
						name: 'Hey Fomo',
						type: 'magazine',
						version: '1.0',
						environment:
							isKiosek || localStorage.getItem('kiosek') === '1'
								? 'board'
								: window.matchMedia('(min-width: 768px)').matches
								? 'desktop'
								: 'mobile',
					},
					errorType: error && error.type,
					tracking: {
						lookForLists: true,
					},
				},
			})
			window.dataLayer?.push({
				page: {
					type: page.type,
				},
				_clear: true,
			})
			if (page.type === 'homepage') {
				window.dataLayer?.push({
					gallery: {},
					_clear: true,
				})
				window.dataLayer?.push({
					article: {},
					_clear: true,
				})
			}

			if (article) {
				window.dataLayer?.push({
					article: {
						id: article.id,
						title: article.title,
						category: article.tags?.map((tag) => {
							return tag.text
						}),
						author: article.authors?.map((author) => {
							return author.name
						}),
						published: article.publishAt,
						contents: [...new Set(article.contentParts)],
					},
					_clear: true,
				})

				window.dataLayer?.push({
					gallery: {},
					_clear: true,
				})
			}

			if (error) {
				window.dataLayer?.push({
					error: {
						type: error.type,
						message: error?.message,
					},
				})
			}
			setDataSent(true)
		}
	}, [dataSent, props.visible, serialized])

	useEffect(() => {
		if (props.visible || !isArticle) {
			if (authStatus === 'signedIn') {
				if (auth.user.id) {
					window.dataLayer?.push({
						_clear: true,
						user: {
							loginStatus: 'loggedIn',
							id: auth.user.id,
						},
					})
				}
			} else if (authStatus === 'signedOut') {
				window.dataLayer?.push({
					_clear: true,
					user: {
						loginStatus: 'anonymous',
					},
				})
			}
			setUserDataSent(true)
		}
	}, [auth, authStatus, isArticle, props.visible])

	useEffect(() => {
		if (dataSent && userDataSent) {
			if ((window as { didomiState?: boolean }).didomiState) {
				window.dataLayer?.push({ event: 'page_body' })
				window.dataLayer?.push({ event: 'page_ready' })
			} else {
				const w = window as { didomiOnReady?: Array<() => void> }
				w.didomiOnReady = w.didomiOnReady || []
				w.didomiOnReady.push(() => {
					window.dataLayer?.push({ event: 'page_body' })
					window.dataLayer?.push({ event: 'page_ready' })
				})
			}
		}
	}, [dataSent, userDataSent])
}
